:root {
  --primary: #A3C6C4;
  --secondary: #D8A0C0;
  --tertiary: #FFF9F8;
  --white: #FFFFFF;
  --gray: #c2c2c2;
}

.markdown {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.markdown ul {
  margin-block-start: 0.1rem;
  margin-block-end: 0.1rem;
  padding-inline-start: 1rem;
}
.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
  line-height: 1.167;
  letter-spacing: 0em;
  margin-bottom: 0.3rem;
}

.markdown img {
  width: 80%;
  margin: auto;
  display: block;
}

.team-img {
  width: 80%;
  margin: auto;
  display: block;
  padding-bottom: 2rem;
}

.ScrollSnapper {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  min-height: calc(100vh - 200px);
}

.ScrollSnapper > * {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  overflow-y: scroll;
}